@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Poppins", sans-serif;
}

body {
  height: 100vh;
  background-image: url('/public/images/starb.png');
  background-size: cover;
  background-position: center;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}

::-webkit-scrollbar-thumb {
  /* background: #FF0000; */
}

.vertical-text {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(180deg);
  white-space: nowrap;
  text-align: center;
}

.service-card {
  transition: transform 0.3s;
}

.service-card:hover {
  transform: scale(1.05);
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
